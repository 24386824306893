
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UaProject } from "@/store/modules/ApiModule";
import { useStore }  from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-ua-project-modal",
  components: {},
  props: {
    uaproject: {
      type: Object as () => UaProject,
      required: true
    }
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addUaProjectModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = reactive<UaProject>({
      id: 0, name: "", client_id: null, tracker_id: null, dashboard: 0, spreadsheet: "", client: {id:0, name:""}
    });
    const store = useStore();

    watch(props.uaproject, (newValue, oldValue) => {
      Object.assign(formData, newValue);
      if(formRef.value){
        formRef.value.clearValidate();
      }
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "UA Project name is required",
          trigger: "blur",
        },
      ],
      spreadsheet: [
        {
          required: true,
          message: "Google Spreadsheet is required",
          trigger: "blur",
        },
      ],
    });

    let appsflyerIntegrations = computed(() => {
      return store.state.api.integrations.filter(integration => integration.thirdparty.id === 1);
    });

    let appsflyerDashboards = computed(() => {
      let integrations = store.state.api.integrations.filter(integration => integration.id == formData.tracker_id);
      if(integrations.length == 0){
        return [{
          name: "Select an Appsflyer",
          id: 0
        }];
      }
      else {
        return integrations[0].data.dashboards;
      }
    });

    onMounted(() => {
      store.dispatch(Actions.LOAD_CLIENTS);
      store.dispatch(Actions.LOAD_INTEGRATIONS);
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          //loading.value = true;
          ApiService.setHeader();
          return ApiService.post("uaprojects", formData)
              .then(() => {
                store.dispatch(Actions.LOAD_UAPROJECTS);
                Swal.fire({
                  text: "UA Project has been saved!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  if(formRef.value){
                    formRef.value.resetFields();
                  }
                  hideModal(addUaProjectModalRef.value);
                });
              })
              .catch((error) => {
                Swal.fire({
                  text: error.response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      store,
      appsflyerIntegrations,
      appsflyerDashboards,
      submit,
      formRef,
      loading,
      addUaProjectModalRef,
    };
  },
});
